import { Link } from "gatsby"
import React, { FC } from "react"
import { Carousel } from "react-responsive-carousel"

interface Props {
  type?: "standard" | "full-page"
}

const FullPage: FC = () => {
  return (
    <div className="h6-slider-area">
      <video
        src="video/hmas-sydney.mp4"
        autoPlay={true}
        loop={true}
        muted={true}
      ></video>
      <div className="h6-slider-full">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="h5-slider-content">
                <h1 className="cd-headline">Knowledge Advantage</h1>
                <p className="text">
                  Delivering capability through people and product to analyse
                  and understand complex problems enabling intelligent,
                  supported and repeatable decisions
                </p>
                <Link className="more" to="about">
                  Read More
                  <span>
                    <i className="fa fa-angle-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Standard: FC = () => {
  return (
    <Carousel
      showArrows={true}
      dynamicHeight={true}
      showStatus={false}
      autoPlay={true}
      transitionTime={350}
      swipeable={true}
    >
      <div className="slider-slide">
        <div
          className="slider-bg"
          style={{
            backgroundImage:
              "url(image/chaz-mcgregor-f66-oTghELc-unsplash.jpg)",
          }}
        ></div>
        <div className="container" style={{ textAlign: "left" }}>
          <h3 className="title-m">We Create Value &amp;</h3>
          <h2
            className="title-b d-none d-md-block"
            style={{ fontFamily: "Byington, serif" }}
          >
            Build Confidence
          </h2>
          <p className="text d-none d-md-block">
            There are many variations of passages of Lorem Ipsum available but
            the majority to have to suffered alteration injected humour.
          </p>
          <a className="slider-more" href="#">
            Read More{" "}
            <span>
              <i className="fa fa-angle-right"></i>
            </span>
          </a>
        </div>
      </div>
      <div className="slider-slide">
        <div
          className="slider-bg"
          style={{
            backgroundImage: "url(image/denise-jans-GRW8Yprjgho-unsplash.jpg)",
          }}
        ></div>
        <div className="container">
          <h3 className="title-m">We Create Value &amp;</h3>
          <h2 className="title-b d-none d-md-block">Build Confidence</h2>
          <p className="text d-none d-md-block">
            There are many variations of passages of Lorem Ipsum available but
            the majority to have to suffered alteration injected humour.
          </p>
          <a className="slider-more" href="#">
            Read More
            <span>
              <i className="fa fa-angle-right"></i>
            </span>
          </a>
        </div>
      </div>
      <div className="slider-slide">
        <div
          className="slider-bg"
          style={{
            backgroundImage:
              "url(image/greg-jeanneau-FIbcvdeNY84-unsplash.jpg)",
          }}
        ></div>
        <div className="container" style={{ textAlign: "right" }}>
          <h3 className="title-m">We Create Value &amp;</h3>
          <h2 className="title-b d-none d-md-block">Build Confidence</h2>
          <p className="text d-none d-md-block">
            There are many variations of passages of Lorem Ipsum available but
            the majority to have to suffered alteration injected humour.
          </p>
          <a className="slider-more" href="#">
            Read More
            <span>
              <i className="fa fa-angle-right"></i>
            </span>
          </a>
        </div>
      </div>
    </Carousel>
  )
}

const Slider: FC<Props> = ({ type, ...props }) =>
  type === "full-page" ? <FullPage {...props} /> : <Standard {...props} />

export default Slider
