import React, { FC } from "react"

const CallOut: FC = () => {
  return (
    <div className="container call-out">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12 col-12">
          <div className="section-title">
            <h6>What We Offer</h6>
            <h2>Explore Our Solutions / Services</h2>
          </div>
          <p>
            Known for delivering results and creating value for our clients. We
            are experienced Subject Matter Experts who understand Information{" "}
            Warfare— both technically and its operational employment
          </p>
        </div>
      </div>
    </div>
  )
}

export default CallOut
