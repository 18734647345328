import React from "react"

import Layout from "../components/layout"
import Slider from "../components/slider"
import SEO from "../components/seo"
import CallOut from "../components/callOut"
import Counter from "../components/counter"
import Cards from "../components/cards"
import CaseStudy from "../components/caseStudy"
import Impact from "../components/impact"
import Brands from "../components/brands"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Slider type="full-page" />
    <CallOut />
    <Impact />
    {/* <Cards />
    <Counter />
    <Brands />
    <CaseStudy /> */}
  </Layout>
)

export default IndexPage
