import React, { FC, ReactNode } from "react"
import { Link } from "gatsby"

export const defaultTitle = "Need a Consultant?"
export const defaultText = `Looking for a Consultant? Contact our team.`
export const defaultBackgroundSrc =
  "image/greg-jeanneau-FIbcvdeNY84-unsplash.jpg"
export const defaultButtonLink = "contact"
export const defaultButtonLabel = "Contact Us"

interface Props {
  title?: ReactNode
  text?: ReactNode
  backgroundSrc?: string
  buttonLabel?: ReactNode
  buttonLink?: string
}

const Impact: FC<Props> = ({
  title = defaultTitle,
  text = defaultText,
  backgroundSrc = defaultBackgroundSrc,
  buttonLabel = defaultButtonLabel,
  buttonLink = defaultButtonLink,
}) => (
  <div
    className="need-consultant-area bg-with-black"
    style={{ backgroundImage: `url('${backgroundSrc}')` }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="need-consultant">
            <h2 className="title">{title}</h2>
            <p className="text">{text}</p>
            <Link className="contact" to={buttonLink}>
              {buttonLabel}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Impact
